import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'x-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-header-layout' },
})
export class HeaderLayoutComponent implements OnInit {
  @Input() containerSize: 'container' | 'container-full' = 'container';
  @Input() headingAlignment: 'left' | 'right' | 'center' = 'center';

  constructor() {}

  ngOnInit(): void {}
}
