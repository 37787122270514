<div [class]="containerSize">
  <header class="header-layout" [class]="'header-align-' + headingAlignment">
    <div class="actions-left">
      <ng-content select="[xActionLeft]"></ng-content>
    </div>
    <div class="heading">
      <ng-content select="[xHeading]"></ng-content>
    </div>
    <div class="actions-right">
      <ng-content select="[xActionRight]"></ng-content>
    </div>
  </header>
</div>
